import { Manager } from "../manager";
import React from "react";
import { User } from "@auth0/auth0-spa-js";
import _ from "lodash";

export default function App(props: { manager: Manager; user?: User }) {

    return (
        <>
            <h1>Wellcome to Shopamine</h1>

            <p>Source: {props.manager.source()}</p>
            <p>Mode: {props.manager.mode()}</p>
            <p>User: {props.user?.email}</p>

            <p>This is shopamine {props.manager.pageType()}</p>

            {props.manager.isLocked() && _.map(props.manager.errors(), (err) => <p key={err}>{err}</p>)}
        </>
    );
}
