"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nullToUndefined = exports.parsSearchQuery = exports.parseSource = exports.parseSourceAsToMode = exports.validateSource = void 0;
function validateSource(input) {
    switch (input) {
        case "login":
            return true;
        case "logout":
            return true;
        case "create":
            return true;
        case "authorize":
            return true;
        default:
            return false;
    }
}
exports.validateSource = validateSource;
function parseSourceAsToMode(input) {
    switch (input) {
        case "login":
            return "LOGIN";
        case "create":
            return "CREATE";
        case "authorize":
            return "LOGIN";
        default:
            return "NONE";
    }
}
exports.parseSourceAsToMode = parseSourceAsToMode;
function parseSource(input) {
    switch (input) {
        case "login":
        case "logout":
        case "create":
        case "authorize":
            return input;
        default:
            throw new Error("Invalid source");
    }
}
exports.parseSource = parseSource;
function parsSearchQuery(url, keys) {
    const params = {};
    for (const key of keys) {
        const value = url.searchParams.get(key);
        if (!value) {
            throw new Error("No key found in search query");
        }
        params[key] = value;
    }
    return params;
}
exports.parsSearchQuery = parsSearchQuery;
function nullToUndefined(input) {
    return input === null ? undefined : input;
}
exports.nullToUndefined = nullToUndefined;
