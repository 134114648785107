import React, { useEffect, useState } from "react";
import { Manager } from "../../manager";

export default function Progress(props: { manager: Manager }) {
    const [data, setData] = useState({
        progress: 0,
        length: 0,
        version: 0,
        isWaiting: false,
    });

    useEffect(() => {
        const progress = (data: any) => {
            setData({
                progress: data.progress,
                length: data.length,
                version: data.version,
                isWaiting: data.isWaiting,
            });
        };

        props.manager.on("progress", progress);

        return () => {
            props.manager.off("progress", progress);
        };
    });

    return (
        <div className="box">
            <h1>Thank you, we are setting up your new store.</h1>
            <div className="outer">
                <div className="inner">
                    <div className="small"></div>
                    <div className="small"></div>
                    <div className="small"></div>
                    <div className="small"></div>
                </div>
                <div className="big">
                    <div className="small"></div>
                </div>
                <div className="big">
                    <div className="small"></div>
                </div>
            </div>
            <p>Hold on, please. It will take about a minute.</p>
            <div className="progress">
                {!data.isWaiting && (
                    <div
                        style={{
                            width: `${data.length > 0 ? (data.version / data.length) * 100 : 0}%`,
                        }}></div>
                )}

                {data.isWaiting && (
                    <div className="slide"></div>
                )}
            </div>
        </div>
    );
}
