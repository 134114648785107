import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Manager } from "../manager";

export default function PageError(props: { manager: Manager; hasError: boolean; errorMsg: string[] }) {
    const [counter, setCounter] = useState(15);

    useEffect(() => {
        const id = setInterval(async () => {
            if (counter - 1 === 0) {
                await props.manager.execSilentLogout();
                props.manager.fallbackRedirect();
            }

            setCounter(counter - 1);
        }, 1000);

        return () => clearInterval(id);
    });

    return (
        <div className="box">
            <h1>Oh no, we hit an error</h1>

            {_.map(props.errorMsg, (err) => (
                <h2 key={err}>{err}</h2>
            ))}

            <p>
                For further information, please contact support: <a href="mailto:support@shopamine.com">support@shopamine.com</a>
            </p>

            <p>
                This page will hide in <b>{counter}</b> seconds.
            </p>
        </div>
    );
}
