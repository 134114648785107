import React, { useEffect, useState } from "react";
import { Manager } from "../manager";
import Error from "./CreateStore/Error";
import Info from "./CreateStore/Info";
import Progress from "./CreateStore/Progress";
import Success from "./CreateStore/Success";

export default function CreateStore(props: { manager: Manager }) {
    const [data, setData] = useState({
        mode: 1,
        link: "",
        storeName: "",
        errorMsg: "",
        hasError: false,
    });

    useEffect(() => {
        const progress = (data: any) => {
            if (data.isCreating && data.mode !== 2) {
                setData({ mode: 2, link: "", storeName: "", hasError: false, errorMsg: "" });
            } else if (data.isDone) {
                //will trigger via event
            } else if (data.hasError) {
                setData({ mode: 4, link: "", storeName: "", hasError: data.hasError, errorMsg: data.error });
            } else if (data.isWaiting) {
                setData({ mode: 2, link: "", storeName: "", hasError: false, errorMsg: "" });
            }
        };
        const error = (err: string) => {
            setData({ mode: 4, link: "", storeName: "", hasError: true, errorMsg: err });
        };
        const success = async (data: any) => {
            if (data.mode !== 3) {
                const link = await props.manager.getLoginLink(data.storeId, data.adminId);

                setData({ mode: 3, link, storeName: data.storeName, hasError: false, errorMsg: "" });
                setTimeout(async () => {
                    await props.manager.execSilentLogout();

                    window.location.assign(link);
                }, 5000);
            }
        };

        props.manager.once("progress", progress);
        props.manager.once("error", error);
        props.manager.once("success", success);

        return () => {
            props.manager.off("progress", progress);
            props.manager.off("error", error);
            props.manager.off("success", success);
        };
    });

    return (
        <>
            {data.mode === 1 && <Info manager={props.manager} next={() => setData({ mode: 2, link: "", storeName: "", hasError: false, errorMsg: "" })} />}
            {data.mode === 2 && <Progress manager={props.manager} />}
            {data.mode === 3 && <Success manager={props.manager} link={data.link} storeName={data.storeName} />}
            {data.mode === 4 && <Error manager={props.manager} hasError={data.hasError} errorMsg={data.errorMsg} />}
        </>
    );
}
