import React, { useState } from "react";
import { Manager } from "../manager";
import { StoreInfo } from "shopamine-api/model";
import _ from "lodash";

export default function SelectStore(props: { manager: Manager; stores: { id: number; storeName: string }[]; storesInfo: StoreInfo[] }) {
    const [data, setData] = useState({
        hasError: false,
        errorMsg: "",
        selectedStoreId: 0,
    });

    const list = [];
    for (const s of props.stores) {
        const store = _.find(props.storesInfo, { storeId: s.id });

        if (!store) {
            // list.push(
            //     <div
            //         key={s.id}
            //         className="list-item"
            //         onClick={async () => {
            //             try {
            //                 const link = await props.manager.getLoginLink(`${s.id}`);

            //                 await props.manager.execSilentLogout();

            //                 window.location.assign(link);
            //             } catch (eRaw) {
            //                 const e = eRaw as any;

            //                 console.error(e);

            //                 setData(
            //                     Object.assign({}, data, {
            //                         errorMsg: e.message || e,
            //                         hasError: true,
            //                     })
            //                 );

            //                 setTimeout(() => {
            //                     setData(
            //                         Object.assign({}, data, {
            //                             errorMsg: "",
            //                             hasError: false,
            //                         })
            //                     );
            //                 }, 3000);
            //             }
            //         }}>
            //         {s.storeName}
            //     </div>
            // );

            list.push(
                <div className="store-card">
                    <div className="flex-columns">
                        <h5>Store {s.storeName} is not connected to this instance of Shopamine.</h5>
                        <p>If you know your stores domain you can try storedomain.si/admin</p>
                    </div>
                </div>
            );

            continue;
        }

        if (store.domains.length === 0) continue;

        list.push(
            <Store
                setData={(newData) => {
                    setData(Object.assign({}, data, newData));
                }}
                manager={props.manager}
                store={store}
                key={store.storeId}
                selectedStoreId={data.selectedStoreId}
                selectStore={(storeId: number) => {
                    setData(Object.assign({}, data, { selectedStoreId: storeId }));
                }}
            />
        );
    }

    return (
        <>
            <h1>Select Store</h1>

            {data.hasError && <div className="error">{data.errorMsg}</div>}

            <div className="grid-container">{list}</div>
        </>
    );
}

export function Store(props: {
    manager: Manager;
    store: StoreInfo;
    selectedStoreId: number;
    selectStore: (storeId: number) => void;
    setData: (data: {
        //
        hasError?: boolean;
        errorMsg?: string;
        selectedStoreId?: number;
    }) => void;
}) {
    const imageId = props.store.logo ? props.store.logo.id : "-1";
    const ext = props.store.logo ? props.store.logo.ext : ".jpg";

    let imageDomain;
    let imageDomainFallback;
    for (const d of props.store.domains) {
        if (d.domain.indexOf(".") !== -1 && !imageDomain) {
            imageDomain = d.domain;
        } else if (!imageDomainFallback) {
            imageDomainFallback = d.domain;
        }
    }

    const src = `https://${imageDomain ? imageDomain : imageDomainFallback}/iimg/${imageId}/i${ext}`;

    return (
        <div className="store-card">
            <div
                className="flex-columns"
                style={{
                    display: props.selectedStoreId === props.store.storeId ? "none" : "flex",
                }}>
                <img src={src} alt={props.store.name ? props.store.name : props.store.storeId + ""} className="store-logo" />
                <div
                    className="button"
                    onClick={() => {
                        props.selectStore(props.store.storeId);
                    }}>
                    {props.store.name}
                </div>
            </div>

            <div
                className={"flex-columns popup " + (props.selectedStoreId === props.store.storeId ? "active" : "")}
            >
                <div className="store-name"
                    onClick={() => {
                        props.selectStore(0);
                    }}>
                    {props.store.name}

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 256a208 208 0 1 1 416 0A208 208 0 1 1 48 256zm464 0A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM223 151l-88 88c-9.4 9.4-9.4 24.6 0 33.9l88 88c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47L360 280c13.3 0 24-10.7 24-24s-10.7-24-24-24l-150.1 0 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0z"/></svg>
                </div>

                <div className="description">Select a store domain you wish to log in to:</div>

                {_.map(props.store.domains, (d) => {
                    return (
                        <div
                            className="list-item"
                            onClick={async () => {
                                try {
                                    const link = await props.manager.getLoginLink(`${props.store.storeId}`, undefined, d.domain);

                                    await props.manager.execSilentLogout();

                                    window.location.assign(link);
                                } catch (eRaw) {
                                    const e = eRaw as any;

                                    console.error(e);

                                    props.setData({
                                        errorMsg: e.message || e,
                                        hasError: true,
                                    });

                                    setTimeout(() => {
                                        props.setData({
                                            errorMsg: "",
                                            hasError: false,
                                        });
                                    }, 3000);
                                }
                            }}>
                            {d.domain}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 24c0 13.3 10.7 24 24 24H430.1L207 271c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l223-223V184c0 13.3 10.7 24 24 24s24-10.7 24-24V24c0-13.3-10.7-24-24-24H328c-13.3 0-24 10.7-24 24zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V440c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z"/></svg>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
