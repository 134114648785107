import React, { useEffect, useState } from "react";
import { Manager } from "../../manager";

export default function Success(props: { manager: Manager; link: string; storeName: string }) {
    const [counter, setCounter] = useState(5);

    useEffect(() => {
        const id = setInterval(() => {
            setCounter(counter - 1);
        }, 1000);

        return () => clearInterval(id);
    });

    return (
        <div className="box">
            <h1>Congratulations!</h1>

            <h2>
                Your store '<span id="success-store-name">{props.storeName}</span>' is ready.
            </h2>

            <p>
                You should be automatically redirected to store administration panel in <b>{counter}</b> seconds.
            </p>

            <p>
                Otherwise you can access it at:&nbsp;
                <a className="pointer" href={props.link}>
                    here
                </a>
                .
            </p>
        </div>
    );
}
