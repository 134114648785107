import { Auth0Client, createAuth0Client } from "@auth0/auth0-spa-js";
import axios from "axios";
import EventEmitter from "events";
import { Mode, SessionRequest, Source, StoreInfo } from 'shopamine-api/model';
import ShopamineAPI from 'shopamine-api/index';
import { parseSource, parseSourceAsToMode, parsSearchQuery, validateSource } from 'shopamine-api/tools';
import jwt_decode from "jwt-decode";
import moment from 'moment';
import slugify from "slugify";

export class Manager extends EventEmitter {

    private state: {
        debug: boolean,
        locked: boolean,
        errors: string[],
        warnings: string[],
        source?: Source,
        token?: string,
        mode: Mode,
        params: { [id: string]: string }
        sessionId?: string,
        jwt?: any,
        createStore?: boolean
    } = {
            debug: process.env.REACT_APP_DEBUG === "true",
            locked: false,
            errors: [],
            warnings: [],
            source: undefined,
            mode: "NONE",
            params: {}
        };
    private auth0: Auth0Client = {} as any;
    private api: ShopamineAPI = new ShopamineAPI(process.env.REACT_APP_SHOPAMINE_META_API || "");
    private sessionRequest?: SessionRequest;
    private createStoreCheckerId: any;

    private storage = window.sessionStorage;

    init = async () => {
        this.auth0 = await createAuth0Client({
            domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
            clientId: process.env.REACT_APP_AUTH0_CLIENTID || "",
            authorizationParams: {
                redirect_uri: process.env.REACT_APP_SHOPAMINE_URL || "",
                scope: process.env.REACT_APP_AUTH0_SCOPE || "",
            },
        });

        if (this.state.debug) {
            await this.logger.log(JSON.stringify(process.env, undefined, 2));
        }

        //this search params will only be presnet if we are handling login with auth0
        if (window.location.search.includes("state=") && (window.location.search.includes("code=") || window.location.search.includes("error="))) {
            await this.logger.log("Handling Auth0")
            await this.auth0.handleRedirectCallback();
            window.history.replaceState({}, document.title, "/");
        }

        const url = new URL(window.location.toString());
        const stateRaw = this.storage.getItem("shopamine");

        const error = url.searchParams.get("err");
        const source = url.searchParams.get("source");
        const mode = source !== null ? parseSourceAsToMode(parseSource(source)) : undefined;

        if (stateRaw) {
            //restore session from session storage
            const state = JSON.parse(stateRaw);

            if (!validateSource(state.source)) {
                this.state.locked = true;
                this.state.errors.push("Invalid source");
                throw new Error("Invalid source");
            }

            if (mode !== undefined && state.mode !== mode) {
                await this.createLocalSession(url, source);
            } else if (source !== null && state.source !== source) {
                await this.createLocalSession(url, source);
            } else {
                this.state = state;

                if (error !== null) {
                    await this.logger.log("We received error from store token login page");
                    this.state.locked = true;
                    this.state.errors.push(error);
                }

                await this.checkSession();
            }
        } else {
            if (error !== null) {
                await this.logger.log("We received error from store token login page");
                this.state.locked = true;
                this.state.errors.push(error);
            }

            await this.createLocalSession(url, source);
        }

        // restore request from state ... as we loose query params at this point
        // we should match pattern or in simple way check if source matches what are we need for setting
        // up session and throw error if missing
        switch (this.state.source) {
            case 'authorize':
                //create right request for session
                this.sessionRequest = {
                    mode: this.state.mode,
                    token: this.state.params.token,
                    data: this.state.params.data,
                }

                try {
                    //this is for debugging
                    this.logger.log(Buffer.from(this.state.params.data, 'base64').toString());
                } catch (eRaw) {
                    const e = eRaw as any;

                    this.logger.log(e.message || e);
                }

                break;
            case 'login':
                //create right request for session
                this.sessionRequest = {
                    mode: this.state.mode,
                    storeId: this.state.params.storeId,
                    domain: this.state.params.domain
                }

                window.document.title = "Login to Shopamine store";

                break;
            case 'logout':
                break;
            case 'create':
                this.sessionRequest = {
                    mode: this.state.mode,
                };

                if (this.state.createStore) {
                    //re init checking for create

                    this.createStoreCheckerId = setInterval(this.checkStoreCreated, 1000);
                }

                window.document.title = "Create a Shopamine store";

                break;
        }

        window.history.replaceState({}, document.title, "/");
    }

    initSession = async () => {
        if (this.state.locked) {
            throw new Error("System is locked can't use any commands");
        }


        try {
            if (!this.sessionRequest) {
                this.state.locked = true;
                this.state.errors.push("No session request was generated in previous step");
                throw new Error("No session request was generated in previous step");
            }

            const token = await this.auth0.getIdTokenClaims();

            if (!token) {
                this.state.locked = true;
                this.state.errors.push("Invalid token");
                throw new Error("Invalid token");
            }

            //decode toke if in login mode and nod storeId
            this.state.jwt = jwt_decode(token.__raw) as any;

            if (this.state.source === "login") {
                if (this.sessionRequest.storeId) {
                    this.state.mode = "LOGIN";
                } else if (this.state.jwt.stores && this.state.jwt.stores.length === 1) {
                    this.state.mode = "LOGIN";
                    this.sessionRequest.storeId = this.state.jwt.stores[0].id;
                } else if (this.state.jwt.stores && this.state.jwt.stores.length > 1) {
                    this.state.mode = "MULTI_LOGIN";
                } else if (!this.state.jwt.stores || (this.state.jwt.stores && this.state.jwt.stores.length === 0)) {
                    this.state.warnings.push("You have no store connected to your account, but you can create one");
                    this.state.mode = "CREATE";
                }
            }

            if (moment(this.state.jwt.exp, "X").isBefore()) {
                this.state.locked = true;
                this.state.errors.push("Token expired");
                throw new Error("Token expired");
            }

            //check state for sessionId
            if (!this.state.sessionId) {
                this.api.update_config({
                    headers: {
                        "Authorization": `Bearer ${token.__raw}`,
                        "Provider": `auth0`
                    }
                });

                const data = await this.api.session(this.sessionRequest);

                await this.logger.log(data);
                this.state.sessionId = data.sessionId;
            } else {
                this.api.update_config({
                    headers: {
                        "Provider": `auth0`,
                        "Session": `${this.state.sessionId}`
                    }
                });

                await this.api.check_session();
            }

            //replace session
            this.storage.setItem("shopamine", JSON.stringify(this.state));
        } catch (eRaw) {
            const e = eRaw as any;

            await this.logger.log(e);

            this.state.locked = true;
            this.state.errors.push(e.message || e);

            await this.execSilentLogout();
            this.fallbackRedirect();
        }
    }

    getDomains = async (storeId?: string) => {
        if (this.state.locked) {
            throw new Error("System is locked can't use any commands");
        }

        try {
            let _storeId = "-1";

            if (storeId) {
                _storeId = storeId;
            } else if (this.state.params.storeId) {
                _storeId = this.state.params.storeId;
            }

            this.api.update_config({
                headers: {
                    "Provider": `auth0`,
                    "Session": `${this.state.sessionId}`
                }
            });

            return await this.api.get_domains(parseInt(_storeId));
        } catch (eRaw) {
            const e = eRaw as any;

            await this.logger.log(e.message || e)

            //Don't lock system if error has accoured
            // this.state.locked = true;
            // this.state.errors.push(e.message || e);

            throw new Error(e.message || e);
        }
    }

    getStoresInfo = async (): Promise<StoreInfo[]> => {
        if (this.state.locked) {
            throw new Error("System is locked can't use any commands");
        }

        try {
            this.api.update_config({
                headers: {
                    "Provider": `auth0`,
                    "Session": `${this.state.sessionId}`
                }
            });

            return (await this.api.get_stores()).data;
        } catch (eRaw) {
            const e = eRaw as any;

            await this.logger.log(e.message || e)

            //Don't lock system if error has accoured
            // this.state.locked = true;
            // this.state.errors.push(e.message || e);

            throw new Error(e.message || e);
        }
    }

    getLoginLink = async (storeId?: string, adminId?: string, domain?: string) => {
        if (this.state.locked) {
            throw new Error("System is locked can't use any commands");
        }

        try {
            let _storeId;

            if (storeId) {
                _storeId = storeId;
            } else if (this.state.params.storeId) {
                _storeId = this.state.params.storeId;
            }

            this.api.update_config({
                headers: {
                    "Provider": `auth0`,
                    "Session": `${this.state.sessionId}`
                }
            });

            if (!domain) {
                domain = this.state.params.domain;
            }

            const res = await this.api.get_login_link({
                storeId: _storeId,
                adminId,
                domain
            });

            if (!domain) {
                domain = res.domain;
            }

            this.storage.clear();
            this.deleteAllCookies();

            let redirectDomain = res.domain;
            if (domain && domain.indexOf(".") === -1) {
                redirectDomain = `${process.env.REACT_APP_OFFSET}${res.domain}`
            }

            return `https://${redirectDomain}/admin/token?signature=${res.signature}&data=${res.data}`;
        } catch (eRaw) {
            const e = eRaw as any;

            await this.logger.log(e.message || e)

            //Don't lock system if error has accoured
            // this.state.locked = true;
            // this.state.errors.push(e.message || e);

            throw new Error(e.message || e);
        }
    }

    createStore = async (storeName: string, domainPart: string) => {
        if (this.state.locked) {
            throw new Error("System is locked can't use any commands");
        }

        try {
            if (this.state.createStore) {
                this.state.locked = true;
                this.state.errors.push("Store is already creating");
                throw new Error("Store is already creating");
            }

            this.api.update_config({
                headers: {
                    "Provider": `auth0`,
                    "Session": `${this.state.sessionId}`
                }
            });

            //TODO: fowrawrd utms

            await this.api.create_store({
                domainPart,
                locale: this.state.params.locale ? this.state.params.locale : "en",
                country: this.state.params.country && this.state.params.country.length > 0 ? this.state.params.country : "intl",
                segmentId: this.state.params.segmentId ? this.state.params.segmentId : "",
                segmentType: this.state.params.segmentType ? this.state.params.segmentType : "",
                storeName,
                templateInstance: this.state.params.templateInstance ? this.state.params.templateInstance : "",
                userId: ""
            });

            this.state.createStore = true;
            this.createStoreCheckerId = setInterval(this.checkStoreCreated, 1000);

            this.storage.setItem("shopamine", JSON.stringify(this.state));
        } catch (eRaw) {
            const e = eRaw as any;

            this.state.locked = true;
            this.state.errors.push(e.message || e);

            this.emit("error", e.message || e);

            await this.logger.log(e);
        }
    }

    checkDomainPart = async (doaminPart: string) => {
        if (this.state.locked) {
            throw new Error("System is locked can't use any commands");
        }

        try {
            this.api.update_config({
                headers: {
                    Provider: `auth0`,
                    Session: `${this.state.sessionId}`,
                },
            });

            const res = await this.api.check_domain(doaminPart);

            return res.result;
        } catch (eRaw) {
            const e = eRaw as any;

            await this.logger.log(e);

            return false;
        }
    }

    getStores = () => this.state.jwt && this.state.jwt.stores;

    isLocked = () => this.state.locked;
    errors = () => this.state.errors;
    warnings = () => this.state.warnings;
    source = () => this.state.source;
    mode = () => this.state.mode;

    isStoreCreating = () => this.state.createStore;

    pageType = () => {
        switch (this.state.source) {
            case "login":
                return "Login page";
            case "create":
                return "Create store";
            case "authorize":
                return "Login page";
        }

        return "";
    }

    isAuthenticated = async () => {
        try {
            return await this.auth0.isAuthenticated();
        } catch (e) {
            await this.logger.log(e)

            return false;
        }
    };

    execLogin = async (mode: "shop_create" | "shop_login") => {
        if (this.state.locked) {
            throw new Error("System is locked can't use any commands");
        }

        await this.auth0.loginWithRedirect({
            appState: {},
            authorizationParams: {
                prompt: "login",
                screen_hint: mode === 'shop_create' ? "signup" : undefined
            },
        });
    }

    execLogout = async () => {
        try {
            this.storage.clear();
            this.deleteAllCookies();

            await this.logger.log("execLogout");

            await this.auth0.logout({
                logoutParams: {
                    returnTo: window.location + "",
                    federated: true
                }
            });
        } catch (e) {
            await this.logger.log(e);
        }
    }

    execSilentLogout = async () => {
        try {
            this.storage.clear();
            this.deleteAllCookies();

            await this.auth0.logout({
                openUrl: async (url) => {
                    //ignore???
                },
                logoutParams: {
                    returnTo: window.location + "",
                    federated: true
                }
            });
        } catch (e) {
            await this.logger.log(e);
        }
    }

    execClearSession = async () => {
        try {
            this.storage.clear();
            this.deleteAllCookies();

            await this.logger.log("execClearSession");
        } catch (e) {
            await this.logger.log(e);
        }
    }


    profile = async () => {
        if (this.state.locked) {
            throw new Error("System is locked can't use any commands");
        }

        try {
            const profile = (
                await axios.get(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`, {
                    headers: {
                        Authorization: `Bearer ${await this.auth0.getTokenSilently()}`,
                    },
                })
            ).data;

            return profile;
        } catch (eRaw) {
            const e = eRaw as any;

            this.state.locked = true;
            this.state.errors.push(e.message || e);

            await this.logger.log(e);
        }
    }

    checkSession = async () => {
        try {
            if (this.state.sessionId) {
                this.api.update_config({
                    headers: {
                        "Provider": `auth0`,
                        "Session": `${this.state.sessionId}`
                    }
                });

                await this.api.check_session();
            }


            return true;
        } catch (e) {
            await this.logger.log(e);

            return false;
        }
    }

    debug = () => this.state.debug;

    fallbackRedirect = () => {
        window.location.assign(process.env.REACT_APP_SHOPAMINE_FALLBACK_URL || "");
    }

    private deleteAllCookies = () => {
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    private checkStoreCreated = async () => {
        try {
            if (this.state.sessionId) {
                this.api.update_config({
                    headers: {
                        "Provider": `auth0`,
                        "Session": `${this.state.sessionId}`
                    }
                });
            }

            const res = await this.api.check_create_status();

            //isCreating: false, isDone: false, isWaiting: false, hasError: false

            if (res.hasError) {
                this.state.locked = true;
                this.state.errors.push(res.error);

                this.emit("error", res.error);
                throw new Error(res.error);
            }

            if (res.isDone && this.createStoreCheckerId) {
                clearInterval(this.createStoreCheckerId);

                this.emit("success", res);
            }

            this.emit("progress", res);
        } catch (e) {
            await this.logger.log(e);
        }
    }

    private createLocalSession = async (url: URL, source: string | null) => {
        if (!source || !validateSource(source)) {
            this.state.locked = true;
            this.state.errors.push("Invalid source");
            throw new Error("Invalid source");
        }

        this.state.source = parseSource(source);
        this.state.mode = parseSourceAsToMode(this.state.source);

        //all url params reading must be done from here or we will loose state data after that
        this.state.params = parsSearchQuery(url, Array.from(url.searchParams.keys()));

        this.storage.setItem("shopamine", JSON.stringify(this.state));

        await this.logger.log(this.state)
    }

    public logger = {
        log: async (message?: any, ...optionalParams: any[]) => {
            console.log(message, ...optionalParams);
            try {
                await axios.post(process.env.REACT_APP_LOGGER || "", {
                    context: this.state,
                    message,
                    ...optionalParams
                });
            } catch (e) {
                console.log(e);
            }
        }

    }
}
