import _ from "lodash";
import React, { useState } from "react";
import { Manager } from "../../manager";
import slugify from "slugify";

export default function Info(props: { manager: Manager; next: () => void }) {
    const [data, setData] = useState({
        hasError: false,
        hasPrereq: false,
        errorMsg: "",
        storeName: "",
        domainPart: ""
    });

    return (
        <div className="box">
            <h1>Please enter your store name</h1>

            {props.manager.warnings().length > 0 && <p className="warning">{_.map(props.manager.warnings(), (elm) => elm)}</p>}

            <div className="form">
                <div className="form-element">
                    <input
                        type="text"
                        value={data.storeName}
                        onChange={async (e) => {
                            const val = e.target.value;
                            const domainPart = slugify(val);

                            let hasError = false;
                            let hasPrereq = true;
                            let errorMsg = "";

                            if (val.length < 3) {
                                hasPrereq = false;
                            }

                            if (hasPrereq) {
                                if (!(await props.manager.checkDomainPart(domainPart))) {
                                    errorMsg = "The name you chose is already taken.";
                                    hasError = true;
                                }

                                if (!/^[a-z][a-z0-9-]*[a-z0-9]$/.test(domainPart)) {
                                    errorMsg = "Please make sure that you use only alphabetic signs a-z or numbers 1-9.";
                                    hasError = true;
                                }
                            }

                            setData({
                                errorMsg,
                                storeName: val,
                                domainPart,
                                hasError,
                                hasPrereq,
                            });
                        }}
                        placeholder="your new store name"
                    />

                    {data.hasError && <span className="form-error">{data.errorMsg}</span>}
                </div>
                <input
                    type="submit"
                    className="button"
                    value="Continue"
                    onClick={async () => {
                        if (!data.hasError && data.hasPrereq) {
                            props.next();
                            await props.manager.createStore(data.storeName, data.domainPart);
                        }
                    }}
                />
            </div>

            <p className="form-desc">
                <i>Store name can be temporary. Your can change it at any time later.</i>
            </p>
        </div>
    );
}
