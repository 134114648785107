import React from "react";
import { Manager } from "../../manager";

export default function Error(props: { manager: Manager; hasError: boolean; errorMsg: string }) {
    return (
        <div className="box">
            <h1>Oh no, we hit an error</h1>

            <h2 id="error-message">{props.errorMsg}</h2>

            <p>
                For further information, please contact support: <a href="mailto:support@shopamine.com">support@shopamine.com</a>
            </p>
        </div>
    );
}
