import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import CreateStore from "./components/CreateStore";
import SelectStore from "./components/SelectStore";
import { Manager } from "./manager";

// import "./assets/css/main.css";
import "./assets/css/main.scss";
import Holder from "./ui/Holder";
import PageError from "./components/PageError";

(async () => {
    const manager = new Manager();

    (window as any).manager = manager;

    let user = undefined;
    try {
        await manager.init();

        await manager.logger.log(manager.source(), manager.mode());

        if (!manager.isLocked()) {
            if (manager.source() === "logout" && (await manager.isAuthenticated())) {
                await manager.execLogout();
                return;
            } else if (manager.source() === "logout") {
                await manager.execClearSession();
                throw new Error("Not logged in redirecting");
            } else if (manager.source() === "create" && !(await manager.isAuthenticated())) {
                await manager.execLogin("shop_create");
                return;
            } else if (manager.source() === "authorize" && !(await manager.isAuthenticated())) {
                await manager.execLogin("shop_create");
                return;
            } else if (manager.source() === "login" && !(await manager.isAuthenticated())) {
                await manager.execLogin("shop_login");
                return;
            }

            user = await manager.profile();

            await manager.initSession();

            switch (manager.source()) {
                case "authorize":
                    //authorize -> will be done at this point in process as session has already finished authorazie process
                    const link = await manager.getLoginLink();

                    await manager.execSilentLogout();

                    window.location.assign(link);

                    break;
                case "login":
                    if (manager.mode() === "LOGIN") {
                        const link = await manager.getLoginLink();

                        await manager.execSilentLogout();

                        window.location.assign(link);
                    }

                    break;
                case "create":
                    // here ...

                    break;
            }
        }
    } catch (eRaw) {
        const e = eRaw as any;

        await manager.logger.log(e);
        await manager.execSilentLogout();
        manager.fallbackRedirect();
    }

    const aaa = [];

    if (manager.debug()) {
        aaa.push(<App manager={manager} user={user} />);
    }

    if (manager.errors().length > 0) {
        aaa.push(<PageError manager={manager} hasError={true} errorMsg={manager.errors()} />);
    } else if (manager.mode() === "MULTI_LOGIN") {
        const storesInfo = await manager.getStoresInfo();
        console.log(storesInfo)
        aaa.push(<SelectStore manager={manager} storesInfo={storesInfo} stores={manager.getStores()} />);
    } else if (manager.mode() === "CREATE") {
        aaa.push(<CreateStore manager={manager} />);
    }

    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
    root.render(
        <React.StrictMode>
            <Holder manager={manager} user={user}>
                {aaa}
            </Holder>
        </React.StrictMode>
    );
})();
